@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// Toggle global options
$enable-gradients: false;
$enable-shadows: false;

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: .4rem;

$primary:       #004f34 !default;
$secondary:     #00ff50 !default;

$font-family-sans-serif: 'Libre Franklin';
$font-family-base: 'Libre Franklin';

@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group"; // Requires forms
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar"; // Requires nav
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";


//
// Custom styles
//

@import "icon-list";


// Style Bootstrap icons
.bi {
  fill: currentcolor;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: #e8f0e8;
  margin-bottom: 80px; /* Margin bottom by footer height */
}

header, footer {
  background-color: $white;
  padding-left: 50px;
  padding-right: 50px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 0.8rem;
  height: 80px; /* Set the fixed height of the footer here */

  & .nav {
    & img {
      max-height: 30px;
      margin-left: 8px;
      margin-top: 8px;
    }
  }
}

.header-logo {
  width: 40px;
  height: 40px;
}

.footer-logo {
  height: 16px;
}

.jumbotron {
  background-color: #e8f0e8;
  background-image: url('../../assets/img/jumbo-bg.jpg');
  background-position-x: right;
  min-height: 500px;
  margin-bottom: 0;

  & h1 {
    font-weight: 800;
    font-size: 4rem;
  }

  & .phone {
    position: absolute;
    top: 40px;
    left: -100px;
  }

  & .download {
    position: absolute;
    bottom: -225px;

    & .display-5 {
      font-size: 2rem;
      margin-bottom: 16px;
    }

    & img {
      display: inline-block;
    }
  }
}

.content {
  background-color: #e8f0e8;
  padding-top: 95px;
  padding-bottom: 75px;
  & p {
    font-size: 1.3rem;
  }
  & .highlight {
    font-weight: 600;
  }
}

@media only screen and (max-width: 768px) {
  body {
    margin-bottom: 180px; /* Margin bottom by footer height */
  }

  footer {
    height: 180px; /* Set the fixed height of the footer here */
  }

  header, footer {
    padding-left: 8px;
    padding-right: 8px;
  }

  .content {
    background-color: #e8f0e8;
    padding-top: 35px;
    padding-bottom: 15px;
  }

  .jumbotron {
    min-height: 400px;

    & h1 {
      font-weight: 800;
      font-size: 3rem;
    }

    & .download {
      bottom: -210px;
      & img {
        max-height: 55px;
      }
    }
  }
}
